.App {
  text-align: center;
}

.App-header {
  background-color: #010101;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #d9d9d9;
}

.coming-soon {
  font-family: "Courier New", Courier, monospace;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.coming-soon.show {
  opacity: 1;
}
